import StepAppBar from "../components/StepAppBar";

export default function Blogs() {
    return (
        <main >
            <StepAppBar/>
            <h2>Blogs</h2>
        </main>
    );
}
