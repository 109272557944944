import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as serviceWorker from './serviceWorker';
import {
    BrowserRouter,
    Routes,
    Route,
} from "react-router-dom";
import Blogs from './routes/Blogs';
import BMICalculator from './routes/BMICalculator';
import PwaExample from "./routes/PwaExample";
import About from "./routes/About";

// ReactDOM.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>,
//   document.getElementById('root')
// );

const rootElement = document.getElementById("root");
ReactDOM.render(
    <BrowserRouter>
        <Routes>
            <Route path="/" element={<App />} />
            <Route path="/stepbetcalculator" element={<App />} />
            <Route path="/bmicalculator" element={<BMICalculator />} />
            <Route path="/saveoffline" element={<PwaExample />} />
            <Route path="/blogs" element={<Blogs />} />
            <Route path="/about" element={<About />} />
        </Routes>
    </BrowserRouter>,
    rootElement
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
// serviceWorker.unregister();
serviceWorker.register();
