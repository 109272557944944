import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import StepAppBar from "../components/StepAppBar";
import ImgWithFallback from "../components/ImgWithFallback";

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
        },
        flexGrow: 1,
        margin: "8px"
    },
    stepCol: {
        margin: "8px",
        '& > *': {
            margin: theme.spacing(1),
        },
    },

    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    buttonText: {
        color: "white"
    }
}));

export default function PwaExample() {
    const classes = useStyles();
    return (
        <div className="App">
            <StepAppBar/>

            <div className={classes.root}>
            <h2>How To Save This Website Offline</h2>

            <p>You may want to save this website offline to access the calculators easily, regardless of your internet
                connection.
            </p>

            <p> This will give you an icon on your iOS, Android, laptop and desktop devices.
            </p>

            <h2>Save this website for iOS devices</h2>
            <img src="./assets/ios_pwa.png" width="80%" height="400" alt="How to save this device your device for online usage"/>

            <h2>Save this website for Android devices</h2>
            <img src="./assets/android_pwa.jpg"  width="200" height="400" alt="How to save this device your device for online usage"/>
                {/*<ImgWithFallback fallback="./assets/ios_pwa.png" src="./assets/ios_pwa.webp"/>*/}
                {/*<ImgWithFallback fallback="./assets/android_pwa.png" src="./assets/android_pwa.webp"/>*/}

            </div>
            </div>
    );
}


