import React, {useEffect} from "react";
import './App.css';
import {makeStyles} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import {ExpandMore, Menu as MenuIcon} from "@material-ui/icons";
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
    Accordion, AccordionDetails, AccordionSummary,
    Avatar,
    Box,
    Checkbox,
    Container, Divider,
    FormControlLabel,
    IconButton,
    Menu,
    MenuItem,
    TextField,
    Tooltip
} from "@material-ui/core";
import {Link} from "react-router-dom";
import StepAppBar from "./components/StepAppBar";
import ImgWithFallback from "./components/ImgWithFallback";

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
        },
        flexGrow: 1,
        margin: "8px"
    },
    stepCol: {
        margin: "8px",
        '& > *': {
            margin: theme.spacing(1),
        },
    },

    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    buttonText: {
        color: "white"
    }
}));

function App() {
    const classes = useStyles();
    const [member, setMember] = React.useState(false);
    const [totalPot, setTotalPot] = React.useState(0);
    const [eligible, setEligible] = React.useState(0);
    const [amountBet, setAmountBet] = React.useState(0);
    const [totalTake, setTotalTake] = React.useState("");
    const [myTake, setMyTake] = React.useState("");

    const headerImage = "./assets/step_image.jpeg";
    let container;


    useEffect(() => {
        loadData();
    }, []);

    const handleChange = (event) => {
        setMember(event.target.checked);
    };

    const saveData = () => {
        localStorage.setItem('totalPot', totalPot.toString());
        localStorage.setItem('eligible', eligible.toString());
        localStorage.setItem('amountBet', amountBet.toString());
        localStorage.setItem('member', member.toString());
    }

    const deleteData = () => {
        localStorage.removeItem('totalPot');
        localStorage.removeItem('eligible');
        localStorage.removeItem('amountBet');
        localStorage.removeItem('member');

        setMember(false);
        setTotalPot(0);
        setEligible(0);
        setAmountBet(0);
    }

    const loadData = () => {
        console.log(localStorage.getItem('totalPot'))

        if (localStorage.getItem('totalPot') !== null) {
            console.log("loading data")
            setTotalPot(parseInt(localStorage.getItem('totalPot')));
        }

        if (localStorage.getItem('eligible') !== null) {
            setEligible(parseInt(localStorage.getItem('eligible')));
        }

        if (localStorage.getItem('amountBet') !== null) {
            setAmountBet(parseInt(localStorage.getItem('amountBet')));
        }

        if (localStorage.getItem('member') !== null) {
            if (localStorage.getItem('member') === 'true') {
                setMember(true);
            } else {
                setMember(false);
            }
        }
    }

    const calculate = () => {

        // console.log(`totalPot is ${typeof totalPot}`)
        // console.log(`amountBet is ${amountBet}`)
        // console.log(`eligible is ${typeof totalPot}`)

        if (totalPot === 0 || eligible === 0 || amountBet === 0 || !totalPot || !eligible || !amountBet) {
            toast.error("Please enter values greater than 0");
            return;
        }

        if (totalPot < eligible) {
            toast.error("The total pot must be greater than the number of eligible members.");
            return;
        }


        if (totalPot < amountBet) {
            toast.error("The total pot must be greater than the amount that you bet.");
            return;
        }

        // let startMembers = totalPot / amountBet;
        let nonMemberPercentage = 0.15;

        let totalProfit = totalPot / eligible;

        if (!member) {
            let percentageTaken = totalPot * nonMemberPercentage;
            totalProfit = (totalPot - percentageTaken) / eligible;
        }

        let yourProfit = totalProfit - amountBet;
        if (yourProfit < 0) {
            totalProfit = amountBet + yourProfit;
            yourProfit = 0;
        }

        if (member) {
            setTotalTake(`Your total take home is $${totalProfit.toFixed(2)}`);
        } else {
            setTotalTake(`Your total take home after Step Bets 15% cut is $${totalProfit.toFixed(2)}`);
        }

        setMyTake(`Your total profit is $${yourProfit.toFixed(2)}`)
    }

    const handleTotalPotChange = (e) => {
        setTotalPot(parseInt(e.target.value));
    }

    const handleEligiblityChange = (e) => {
        setEligible(parseInt(e.target.value));
    }

    const handleBetChange = (e) => {
        setAmountBet(parseInt(e.target.value));
    }


    return (
        <div className="App">
            <StepAppBar/>
            <img src={headerImage} height="250" width="300" alt="unsplash"/>
            {/*<ImgWithFallback fallback={headerImageFallback} src={headerImage}/>*/}
            <div>Photo by <a
                href="https://unsplash.com/@bruno_nascimento?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Bruno
                Nascimento</a> on <a
                href="https://unsplash.com/s/photos/step?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a>
            </div>
            <form className={classes.root} noValidate autoComplete="off">
                {/*<div className={classes.formCol}>*/}
                <TextField id="totalPot" type="number" value={totalPot} label="Total Pot"
                           onChange={handleTotalPotChange}
                           variant="outlined"/>
                <TextField id="eligible" type="number" value={eligible} label="Eligible Members"
                           onChange={handleEligiblityChange}
                           variant="outlined"/>
                <TextField id="amountBet" type="number" value={amountBet} label="Amount Bet" onChange={handleBetChange}
                           variant="outlined"/>
                {/*</div>*/}
            </form>
            <FormControlLabel
                control={<Checkbox checked={member} onChange={handleChange} name="member"/>}
                label="Member Only Game?"
            />

            <div className={classes.stepCol}>
                <Button onClick={() => calculate()} variant="contained" color="primary">
                    Calculate
                </Button>
                <div>{totalTake}</div>
                <div>{myTake}</div>
                <Divider></Divider>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMore/>}
                        aria-controls="panel1-content"
                        id="panel1-header"
                    >
                        More Info on Saving Data
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            Data is not saved by default. Consider using the 'Save Data' button below so you don't have
                            to keep entering all of the data each time.
                            This only saves it to the device you are using. You can use the 'Delete Data' button to
                            delete the data.
                            If you would like to option to save data across devices, please fill out feedback form below
                            and we'll consider adding this feature with enough support.
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Button onClick={() => saveData()} variant="contained" color="primary">
                    Save Data
                </Button>
                <Button onClick={() => deleteData()} variant="contained" color="primary">
                    Delete Data
                </Button>
            </div>

            <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                '& > :not(style)': {m: 1, width: '25ch'},
            }}>

                <Divider></Divider>

                <h3>Fitness Sales</h3>
                <ul>
                    <li>
                        <a href={"https://amzn.to/3Vxu2ye"} target="_blank">Get the best smart scale measuring BMI, Body
                            Composition and more! Comes with an app for tracking your weight</a>
                    </li>
                    <li>
                        <a href={"https://amzn.to/4bONw7h"} target="_blank">Get an apple watch to track your steps. It
                            also has features such as crash detection and heart rate monitor.</a>
                    </li>
                    <li>
                        <a href={"https://amzn.to/3x9WpsL"} target="_blank">Get this Fitbit to track your steps. It
                            also has features such as stress management, sleep detection, and heart rate monitor.</a>
                    </li>
                </ul>

                <br/>
                <Divider></Divider>
                <br/>

                <a href={"https://forms.gle/uMcuPnD1WBN1QZPd8"} target="_blank">Give Feedback</a>

            </Box>
            <br/>
            <Button
                color="primary"
                variant="contained"
                key={"pwa"}
                component={Link} to="/saveoffline"
                sx={{my: 2, color: 'white', display: 'block', marginBottom: '5px'}}
            >
                <div className={classes.buttonText}>Save calculator for offline use</div>
            </Button>

            <ToastContainer
                position="bottom-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />

        </div>
    );
}

export default App;
