import StepAppBar from "../components/StepAppBar";
import {makeStyles} from "@material-ui/core/styles";
import {Checkbox, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, TextField} from "@material-ui/core";
import React from "react";
import Button from "@material-ui/core/Button";
import {ToastContainer} from "react-toastify";
import '../App.css';
import {Link} from "react-router-dom";
import ImgWithFallback from "../components/ImgWithFallback";


const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
        },
        flexGrow: 1,
        margin: "8px"
    },
    stepCol: {
        margin: "8px",
        '& > *': {
            margin: theme.spacing(1),
        },
    },

    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    buttonText: {
        color: "white"
    }
}));


export default function BMICalculator() {
    const classes = useStyles();
    const [height, setHeight] = React.useState(0);
    const [inches, setInches] = React.useState(0);
    const [weight, setWeight] = React.useState(0);
    const [currentBmi, setCurrentBmi] = React.useState(0.0);
    const [result, setResult] = React.useState("");
    const [metric, setMetric] = React.useState("standard");
    const [heightText, setHeightText] = React.useState("Feet");

    const headerImageFallback = "../assets/bmi.jpeg";
    const headerImage = "../assets/bmi.webp";
    let container;


    const calculateBMI = () => {

        /* Getting input from user into height variable.
        Input is string so typecasting is necessary. */
        let heightToUse = height;
        if (metric === 'standard') heightToUse = (height * 12) + inches;

        console.log(`metric is ${metric}`)
        console.log(`height is ${height}`)
        console.log(`heightToUse is ${heightToUse}`)
        console.log(`weight is ${weight}`)

        let bmiResult = "";

        // Checking the user providing a proper
        // value or not
        if (heightToUse === "" || isNaN(heightToUse))
            bmiResult = "Provide a valid Height!";

        else if (weight === "" || isNaN(weight))
            bmiResult = "Provide a valid Weight!";

        // If both input is valid, calculate the bmi
        else {

            // Fixing upto 2 decimal places
            let bmi = (weight / (heightToUse * heightToUse)).toFixed(2);


            if (metric === "standard") {
                bmi = ((weight / (heightToUse * heightToUse)) * 703).toFixed(2);
            }

            console.log(`bmi is ${bmi}`);


            setCurrentBmi(bmi);
            // Dividing as per the bmi conditions
            if (bmi < 18.6) bmiResult =
                `Under Weight: `;

            else if (bmi >= 18.6 && bmi < 24.9)
                bmiResult =
                    `Normal: `;

            else bmiResult =
                    `Over Weight : `;
        }
        setResult(bmiResult);
    }

    const handleHeightChange = (e) => {
        setHeight(parseInt(e.target.value));
    }

    const handleInchesChange = (e) => {
        setInches(parseInt(e.target.value));
    }

    const handleWeightChange = (e) => {
        setWeight(parseInt(e.target.value));
    }

    const handleMetricChange = (e) => {
        setMetric(e.target.value);
        if (metric === 'metric') {
            setHeightText("Height");
        } else {
            setHeightText("Feet");
        }
    }

    const getBMIElement = () => {
        return currentBmi === 0 ? <div></div> : <strong>{currentBmi}</strong>

    }


    return (
        <main className="App">
            <StepAppBar/>

            <img src={headerImage} height="300" width="400" alt="unsplash"/>
            {/*<ImgWithFallback fallback={headerImageFallback} src={headerImage}*/}
            <div>
                Photo by <a
                href="https://unsplash.com/@neonbrand?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Kenny
                Eliason</a> on <a
                href="https://unsplash.com/s/photos/bmi?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a>
            </div>
            <h2>BMI Calculator</h2>
            <form className={classes.root} noValidate autoComplete="off">
                <TextField id="height" type="number" value={height} label={heightText}
                           InputProps={{inputProps: {min: 1}}}
                           onChange={handleHeightChange}
                           variant="outlined"/>
                {
                    metric === 'standard' ?
                        <TextField id="inches" type="number" value={inches} label="Inches"
                                   onChange={handleInchesChange}
                                   variant="outlined"/> : <div></div>
                }

                <TextField id="weight" type="number" value={weight} label="Weight"
                           onChange={handleWeightChange}
                           variant="outlined"/>
            </form>
            <FormControl>
                {/*<FormLabel id="demo-controlled-radio-buttons-group">Metric</FormLabel>*/}
                <RadioGroup
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    value={metric}
                    onChange={handleMetricChange}
                >
                    <FormControlLabel value="standard" control={<Radio/>} label="Standard"/>
                    <FormControlLabel value="metric" control={<Radio/>} label="Metric"/>
                </RadioGroup>
            </FormControl>


            <div className={classes.stepCol}>
                <Button onClick={() => calculateBMI()} variant="contained" color="primary">
                    Calculate BMI
                </Button>
                <div>{result}{getBMIElement()}</div>
            </div>

            <hr/>

            <h3>Fitness Sales</h3>
            <ul>
                <li>
                    <a href={"https://amzn.to/3Vxu2ye"} target="_blank">Get the best smart scale measuring BMI, Body
                        Composition and more! Comes with an app for tracking your weight</a>
                </li>
                <li>
                    <a href={"https://amzn.to/4bONw7h"} target="_blank">Get an apple watch to track your steps. It
                        also has features such as crash detection and heart rate monitor.</a>
                </li>
                <li>
                    <a href={"https://amzn.to/3x9WpsL"} target="_blank">Get this Fitbit to track your steps. It
                        also has features such as stress management, sleep detection, and heart rate monitor.</a>
                </li>
            </ul>

            <hr/>

            <a href={"https://forms.gle/uMcuPnD1WBN1QZPd8"} target="_blank">Give Feedback</a>


            <hr/>
            <Button
                color="primary"
                variant="contained"
                key={"pwa"}
                component={Link} to="/saveoffline"
                sx={{my: 2, color: 'white', display: 'block'}}
            >
                <div className={classes.buttonText}>Save calculator for offline use</div>
            </Button>

            <ToastContainer
                position="bottom-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />

        </main>
    );
}
