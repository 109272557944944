import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import StepAppBar from "../components/StepAppBar";

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
        },
        flexGrow: 1,
        margin: "8px"
    },
    stepCol: {
        margin: "8px",
        '& > *': {
            margin: theme.spacing(1),
        },
    },

    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    buttonText: {
        color: "white"
    }
}));

export default function About() {
    const classes = useStyles();
    return (
        <div className="App">
            <StepAppBar/>

            <div className={classes.root}>
                <h2>About</h2>

                <p>These calculators were built to help motivate and make your life easier!</p>
                <p>Not affiliated with Stepbet or any company.</p>
                <p>Wishing you the best!</p>
            </div>
        </div>
    );
}


